
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';
import SearchTable from '@/components/table/SearchTable.vue';
import TableModel from '@/components/composite/TableModel.vue';

/**
 * 流量卡费率变更记录
 */
export default defineComponent({
  name: 'SimCardModify',
  components: { TableModel, SearchTable },
  setup() {
    const { https, mapper, message } = useInject();
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'targetUid',
              label: '代理',
              type: 'agent',
              required: true
            }
          ]
        },
        remote: {
          action: https?.market.pageSimCardModify
        }
      },
      cols: [
        {
          field: 'orderno',
          label: '返现记录号'
        },
        {
          field: 'col-basic',
          label: '基础信息',
          width: 160,
          group: [
            {
              field: 'type',
              label: '类型',
              options: mapper?.market.simCardModifyType
            },
            {
              field: 'statusDesc',
              label: '状态'
            },
          ]
        },
        {
          field: 'col-uid',
          label: 'ID',
          group: [
            {
              field: 'submitterUid',
              label: '发起人'
            },
            {
              field: 'targetUid',
              label: '变更人'
            }
          ]
        },
        {
          field: 'col-phone',
          label: '手机号',
          group: [
            {
              field: 'submitterPhone',
              label: '发起人'
            },
            {
              field: 'targetPhone',
              label: '变更人'
            }
          ]
        },
        {
          field: 'col-alias',
          label: '用户名',
          group: [
            {
              field: 'submitterAlias',
              label: '发起人'
            },
            {
              field: 'targetAlias',
              label: '变更人'
            }
          ]
        },
        {
          field: 'remark',
          label: '备注',
          width: 120
        },
        {
          field: 'col-time',
          label: '时间',
          width: 220,
          group: [
            {
              field: 'createTime',
              label: '创建'
            },
            {
              field: 'finishTime',
              label: '修改'
            }
          ]
        }
      ],
      commands: [] as any[]
    };

    const detailRef = ref();
    const detailOpts = reactive({
      title: '变更明细',
      visible: false,
      content: {
        cols: [
          {
            field: 'simCardTypeCode',
            label: '流量卡编号'
          },
          {
            field: 'simCardTypeName',
            label: '流量卡名称'
          },
          {
            field: 'profit',
            label: '返现金额'
          }
        ]
      }
    });

    const showDetail = (data: DataItem) => {
      https?.market.infoSimCardModify({ orderno: data.orderno }).then(res => {
        if (res.respType === 'OK') {
          detailOpts.visible = true;
          detailRef.value.init(res.respData.details);
        } else {
          message?.warn(res.respMesg);
        }
      });
    };
    tableOpts.commands.push({ label: '详情', action: showDetail });
    return { tableOpts, detailRef, detailOpts };
  }
});
